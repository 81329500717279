<template>
    <div class="d-flex justify-end mx-4 w-full mt-4">
        <div class="align-center">
            <v-btn class="" variant="text" @click="resetGridFiltersSettings">Reset Filters</v-btn>
            <v-btn class="mr-2" variant="text" @click="resetGridColSettings">Reset Columns</v-btn>
        </div>
    </div>
</template>

<script setup>

import { useRouter } from "vue-router";
import {
    resetGridFilterState,
    resetGridColState,
} from "@/utils/agGridUtils";

const router = useRouter();
const props = defineProps({
    gridSaveKey: String
});
const resetGridFiltersSettings = () => {
    resetGridFilterState(props.gridSaveKey, props.gridApi);
    router.go();
};

const resetGridColSettings = () => {
    resetGridColState(props.gridSaveKey, props.gridApi);
    router.go();
};

</script>